/**
 * Created by amine on 11/02/2019.
 */
(function () {
    'use strict';

    class FileBlockController {
        constructor($q, $scope) {
            this.$q = $q;
            this.$scope = $scope;

            this.key = null;
            this.patient = null;
            this.label = "";
            this.readonly = false;
            this.postSave = _.noop;
            this.postSaveSubject = null;
            this.uploadContext = null;
            this.consultationId = null;
        }

        static get $inject() {
            return ["$q", "$scope"];
        }

        $onInit() {
            this.consultationId = _.isNil(this.postSaveSubject) ? null : this.postSaveSubject.getValue();
            this.setContext();
        }

        preSave() {
            const deferred = this.$q.defer();
            let subscription = this.postSaveSubject.subscribe((data) => {
                this.consultationId = data;
                this.setContext()
                    .then(() => {
                        subscription.unsubscribe();
                        deferred.resolve(this.consultationId);
                    })
            })
            this.postSave();

            return deferred.promise;
        }

        setContext() {
            const deferred = this.$q.defer();

            this.$scope.$applyAsync(() => {
                this.uploadContext = {block: this.key, consultation: this.consultationId}
                setTimeout(() => deferred.resolve(null));
            });

            return deferred.promise;
        }
    }

    module.exports = {
        bindings: {
            key: "@mnBlockKey",
            patient: "<",
            label: "@?",
            readonly: "&?",
            postSave: '&?',
            postSaveSubject: '<?',
            labelColor: "@?"
        },
        controllerAs: "vm",
        controller: FileBlockController,
        template: require('stand-alone/blocks/views/file-block.tpl.html')
    };

})();